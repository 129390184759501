import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Header from "./components/Header";
import DrawerComponent from "./components/DrawerComponent";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";

export default function Dashboard({ children, idToken, setIdToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    drawerOpen: false,
  });

  // Handle logging out
  const handleLogout = () => {
    setIdToken("");
    localStorage.removeItem("authSession");
    Auth.signOut();
  };

  // Handle toggling the drawer
  const handleDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  // If user is not authenticated, redirect to login page
  if (!idToken) {
    return <Navigate to="/login" />;
  }

  // Drawer and Header components
  return (
    <Box sx={{ display: "flex", maxWidth: "100%" }}>
      <DrawerComponent
        drawerOpen={state.drawerOpen}
        handleDrawer={handleDrawer}
      />
      <Box
        sx={{
          flexGrow: 1,
          px: "30px",
          mt: state.drawerOpen ? "200px" : "0px",
        }}
      >
        <Header
          idToken={idToken}
          handleLogout={handleLogout}
          handleDrawer={handleDrawer}
        />
        {children}
      </Box>
    </Box>
  );
}
