import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import validatePasswordCriteria from "../functions/validatePasswordCriteria";
import {
  Box,
  Stack,
  Typography,
  Link,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import duxlylogo from "../assets/images/duxly-logo-zwart.png";
import compass from "../assets/images/compass.png";
import BoxStyle from "../styles/BoxStyle";
import { useTheme } from "@mui/material/styles";

export default function Register({ setIdToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    askForConfirmationCode: false,
    passwordMismatch: false,
    invalidEmail: false,
    userExists: false,
    passwordError: false,
    unexpectedError: false,
    confirmationCodeIsInvalid: false,
    newConfirmationCodeSend: false,
  });

  // Variables
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();
  const location = useLocation();
  const {
    askForConfirmationCode: redirectedAskForConfirmationCode,
    username: redirectedUsername,
    password: redirectedPassword,
  } = location.state || {};
  const urlParams = new URLSearchParams(window.location.search);
  const record_id = urlParams.get("record_id");
  const navigate = useNavigate();

  // Handle change for string states
  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };

  // useEffect for redirecting states
  useEffect(() => {
    if (redirectedAskForConfirmationCode) {
      setState((prevState) => ({
        ...prevState,
        askForConfirmationCode: true,
        username: redirectedUsername,
        password: redirectedPassword,
      }));
    }
  }, [
    redirectedAskForConfirmationCode,
    redirectedUsername,
    redirectedPassword,
  ]);

  // Handle sign up
  const handleSignUp = async () => {
    const emailIsValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
      state.username
    );
    const passwordMeetsCriteria = validatePasswordCriteria(state.password);
    const passwordIdentical = state.password === state.confirmPassword;
    if (emailIsValid && passwordMeetsCriteria && passwordIdentical) {
      try {
        await Auth.signUp({
          username: state.username,
          password: state.password,
        });
        setState({ ...state, askForConfirmationCode: true });
      } catch (error) {
        if (error.code === "UsernameExistsException") {
          try {
            await Auth.resendSignUp(state.username);
            setState({
              ...state,
              askForConfirmationCode: true,
              userExists: false,
            });
          } catch (resendError) {
            setState({
              ...state,
              userExists: true,
              unexpectedError: false,
              invalidEmail: false,
              passwordError: false,
              passwordMismatch: false,
            });
          }
        } else {
          setState({
            ...state,
            userExists: false,
            unexpectedError: true,
            invalidEmail: false,
            passwordError: false,
            passwordMismatch: false,
          });
        }
      }
    } else {
      setState({
        ...state,
        invalidEmail: !emailIsValid,
        passwordError: !passwordMeetsCriteria,
        passwordMismatch: !passwordIdentical,
        userExists: false,
        unexpectedError: false,
      });
    }
  };

  // Link the User ID to the Airtable Record ID
  const linkUserIdToRecordId = async (id_token) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          record_id: record_id,
          id_token: id_token,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_LINK_USER_ID_TO_RECORD_ID,
        requestOptions
      );
      if (!response.ok) {
        console.error("HTTP error! Status: ", response.status);
      }
    } catch (error) {
      setState({ ...state, unexpectedError: true });
    }
  };

  // Navigate to Login
  const toLogin = () => {
    if (record_id) {
      navigate("/login?record_id=" + record_id);
    } else {
      navigate("/login");
    }
  };

  // Await confirmation code and confirm sign up
  const handleConfirmSignUp = async () => {
    const confirmationCodeIsValid = /^\d{6}$/.test(state.confirmationCode);
    if (confirmationCodeIsValid) {
      try {
        await Auth.confirmSignUp(state.username, state.confirmationCode);
        await Auth.signIn(state.username, state.password);
        const data = await Auth.currentSession();
        const idToken = data.getIdToken().getJwtToken();
        await setIdToken(idToken);
        if (record_id) {
          await linkUserIdToRecordId(idToken);
        }
        navigate("/");
      } catch (error) {
        if (error.code === "CodeMismatchException") {
          setState({
            ...state,
            codeMismatch: true,
            unexpectedError: false,
            confirmationCodeIsInvalid: false,
          });
        } else {
          setState({
            ...state,
            codeMismatch: false,
            unexpectedError: true,
            confirmationCodeIsInvalid: false,
          });
        }
      }
    } else {
      setState({
        ...state,
        confirmationCodeIsInvalid: !confirmationCodeIsValid,
        codeMismatch: false,
        unexpectedError: false,
      });
    }
  };

  // Resend confirmation code
  const newConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(state.username);
      setState({
        ...state,
        unexpectedError: false,
        newConfirmationCodeSend: true,
      });
    } catch (error) {
      setState({
        ...state,
        unexpectedError: true,
        newConfirmationCodeSend: false,
      });
    }
  };

  // Register
  return (
    <Stack direction={desktopSize ? "row" : "column"} spacing={2} margin={0}>
      <Box sx={{ ...BoxStyle, maxWidth: desktopSize ? "60%" : "100%" }}>
        <Stack alignItems="left" sx={{ width: "500px" }}>
          <Box sx={{ maxWidth: "300px", px: "30px", py: "30px" }}>
            <img src={duxlylogo} alt="logo" />
          </Box>
          <Stack spacing={2}>
            <Box sx={{ px: "30px", py: "30px" }}>
              <Stack spacing={2}>
                <Typography variant="h4">Register</Typography>
                <Typography variant="body">
                  Enter your account details below to register.
                </Typography>
                {!state.askForConfirmationCode && (
                  <Stack spacing={2}>
                    <TextField
                      type="text"
                      placeholder="Email"
                      onChange={handleChange("username")}
                      error={state.invalidEmail}
                      helperText={
                        state.invalidEmail ? "Not a valid email address." : ""
                      }
                    />
                    <TextField
                      type="password"
                      placeholder="Password"
                      onChange={handleChange("password")}
                      error={state.passwordError}
                      helperText={
                        state.passwordError
                          ? "Password must contain at least 1 number, 1 special character, 1 lowercase letter, 1 uppercase letter, and has to be at least 8 characters long."
                          : ""
                      }
                    />
                    <TextField
                      type="password"
                      placeholder="Confirm Password"
                      onChange={handleChange("confirmPassword")}
                      error={state.passwordMismatch}
                      helperText={
                        state.passwordMismatch ? "Passwords do not match!" : ""
                      }
                    />
                    <Button
                      onClick={handleSignUp}
                      variant="contained"
                      size="large"
                      sx={{
                        px: "20px",
                        py: "10px",
                        borderRadius: 7,
                        boxShadow: 0,
                        backgroundColor: theme.palette.red.main,
                        ":hover": {
                          boxShadow: 0,
                          backgroundColor: theme.palette.red.light,
                        },
                      }}
                    >
                      Sign up
                    </Button>
                    {state.userExists && (
                      <Alert severity="error">
                        Email address is already in use!
                      </Alert>
                    )}
                    {state.unexpectedError && (
                      <Alert severity="error">
                        An unexpected error occured.
                      </Alert>
                    )}
                  </Stack>
                )}
                {state.askForConfirmationCode && (
                  <Stack spacing={2}>
                    <TextField
                      type="text"
                      placeholder="Confirmation Code"
                      onChange={handleChange("confirmationCode")}
                      error={state.confirmationCodeIsInvalid}
                      helperText={
                        state.confirmationCodeIsInvalid
                          ? "Confirmation code must be numbers and has to be 6 characters long."
                          : ""
                      }
                    />
                    {state.newConfirmationCodeSend ? (
                      <Typography
                        color="#AFAFAF"
                        variant="body2"
                        style={{ marginTop: "5px" }}
                      >
                        New verification code sent
                      </Typography>
                    ) : (
                      <Link
                        onClick={newConfirmationCode}
                        underline="hover"
                        color="#AFAFAF"
                        variant="body2"
                        style={{ marginTop: "5px", cursor: "pointer" }}
                      >
                        Need a new confirmation code?
                      </Link>
                    )}
                    <Button
                      onClick={handleConfirmSignUp}
                      variant="contained"
                      size="large"
                      sx={{
                        px: "20px",
                        py: "10px",
                        borderRadius: 7,
                        boxShadow: 0,
                        backgroundColor: theme.palette.red.main,
                        ":hover": {
                          boxShadow: 0,
                          backgroundColor: theme.palette.red.light,
                        },
                      }}
                    >
                      Confirm sign up
                    </Button>
                    {state.codeMismatch && (
                      <Alert severity="error">
                        Invalid verification code provided.
                      </Alert>
                    )}
                    {state.unexpectedError && (
                      <Alert severity="error">
                        An unexpected error occured.
                      </Alert>
                    )}
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
          <Typography variant="body" sx={{ px: "30px", py: "30px" }}>
            Have an account? &nbsp;
            <Link
              onClick={toLogin}
              underline="hover"
              sx={{ cursor: "pointer", color: theme.palette.red.main }}
            >
              Login here!
            </Link>
          </Typography>
        </Stack>
      </Box>
      {desktopSize && (
        <Box
          sx={{
            ...BoxStyle,
            backgroundColor: "#e53a1c",
            maxWidth: "40%",
          }}
        >
          <Box
            sx={{
              flex: "auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              maxWidth: "600px",
            }}
          >
            <img src={compass} alt="compass" />
          </Box>
        </Box>
      )}
    </Stack>
  );
}
