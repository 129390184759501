import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { awsExports } from "./aws-exports";
import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PasswordReset from "./pages/PasswordReset";
import MyApps from "./pages/MyApps";
import Dashboard from "./Dashboard";
import GoogleFeed from "./pages/GoogleFeed";
import ContentCreator from "./pages/ContentCreator";
import TemplateApp from "./pages/TemplateApp";
import { Box } from "@mui/material";
import MyAccount from "./pages/MyAccount";

// Configures AWS userpool data
Auth.configure(awsExports);

export default function App() {
  // Manage all the different states
  const [state, setState] = useState({
    idToken: "",
    loading: true,
  });

  // Update idToken state
  const setIdToken = (idToken) => {
    setState((prevState) => ({ ...prevState, idToken: idToken }));
  };

  // useEffect for checking idToken state
  useEffect(() => {
    const checkAuthState = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        setState((prevState) => ({ ...prevState, idToken: idToken }));
      } catch (error) {
      } finally {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    };
    checkAuthState();
  }, []);

  // Update document title based on url
  const UpdateDocumentTitle = () => {
    const location = useLocation();
    useEffect(() => {
      let title;
      switch (location.pathname) {
        case "/":
          title = "Duxly Dashboard | Home";
          break;
        case "/my-apps":
          title = "Duxly Dashboard | My Apps";
          break;
        case "/my-account":
          title = "Duxly Dashboard | My Account";
          break;
        case "/my-apps/google-feed":
          title = "Duxly Dashboard | Google Feed";
          break;
        case "/register":
          title = "Duxly Dashboard | Register";
          break;
        case "/password-reset":
          title = "Duxly Dashboard | Password Reset";
          break;
        case "/login":
          title = "Duxly Dashboard | Login";
          break;
        default:
          title = "Duxly Dashboard";
      }
      document.title = title;
    }, [location.pathname]);
    return null;
  };

  // Return the login page or dashboard depending on idToken
  if (!state.loading) {
    return (
      <BrowserRouter>
        <UpdateDocumentTitle />
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard setIdToken={setIdToken} idToken={state.idToken}>
                <Home />
              </Dashboard>
            }
          />
          <Route
            path="/my-apps"
            element={
              <Dashboard setIdToken={setIdToken} idToken={state.idToken}>
                <MyApps idToken={state.idToken} />
              </Dashboard>
            }
          />
          <Route
            path="/my-account"
            element={
              <Dashboard setIdToken={setIdToken} idToken={state.idToken}>
                <MyAccount setIdToken={setIdToken} idToken={state.idToken} />
              </Dashboard>
            }
          />
          <Route
            path="/my-apps/google-feed"
            element={
              <Dashboard setIdToken={setIdToken} idToken={state.idToken}>
                <GoogleFeed idToken={state.idToken} />
              </Dashboard>
            }
          />
          <Route
            path="/my-apps/content-creator"
            element={
              <Dashboard setIdToken={setIdToken} idToken={state.idToken}>
                <ContentCreator idToken={state.idToken} />
              </Dashboard>
            }
          />
          <Route
            path="/my-apps/product-sync"
            element={
              <Dashboard setIdToken={setIdToken} idToken={state.idToken}>
                <TemplateApp idToken={state.idToken} />
              </Dashboard>
            }
          />
          <Route
            path="/register"
            element={<Register setIdToken={setIdToken} />}
          />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/login" element={<Login setIdToken={setIdToken} />} />
        </Routes>
      </BrowserRouter>
    );
  }

  // Return empty html if still loading
  return <Box />;
}
