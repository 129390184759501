import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  Box,
  Typography,
  List,
  ListItemText,
  ListItem,
  FormHelperText,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import filterRecordIdWithUserId from "../functions/filterRecordIdWithUserId";
import getAvailableLanguages from "../functions/getAvailableLanguages";
import useFilterShopIdWithUserId from "../functions/useFilterShopIdWithUserId";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { languageNames } from "../variables/languageNames";

export default function GoogleFeed({ idToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    shopId: "",
    language: "",
    shopIds: [],
    csvLink: "",
    languageError: false,
    shopIdError: false,
    isLoadingCSV: false,
    records: [],
    googleFeed: false,
    isCopied: false,
    installedFeeds: [],
    triggerCSVLink: false,
    triggerCopyLinkToClipboard: false,
    csvLinkGenerated: false,
    copiedButtons: {},
    languageSelect: false,
    availableLanguages: [],
  });

  // Variables
  const filterShopIdWithUserId = useFilterShopIdWithUserId();
  const theme = useTheme();
  const lightenColor = (color, amount) => alpha(color, amount);
  const desktopSize = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  // Handle change for string states
  const handleChange = (key) => (e) => {
    setState((prevState) => ({
      ...prevState,
      [key]: e.target.value,
      [`${key}Error`]: false,
    }));
  };

  // Post values necessary to upload config.json to S3
  const uploadToS3 = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_token: idToken,
        shop_id: state.shopId,
        language: state.language,
      }),
    };
    const response = await fetch(
      process.env.REACT_APP_UPLOAD_TO_S3,
      requestOptions
    );
    if (!response.ok) {
      console.error("HTTP error! Status: ", response.status);
    }
  };

  // Post values for generateCSV
  const generateCSV = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_token: idToken,
        shop_id: state.shopId,
        language: state.language,
        schedule: "my_catalog_feed",
      }),
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_SHOPSYNC,
        requestOptions
      );
      if (!response.ok) {
        console.error("HTTP error! Status: ", response.status);
      }
    } catch (error) {
      console.error("An unexpected error occured: ", error);
    }
  };

  // Get the link for generated CSV file
  const getCSVLink = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_token: idToken,
        shop_id: state.shopId,
        language: state.language,
      }),
    };
    const response = await fetch(
      process.env.REACT_APP_GET_CSV_LINK,
      requestOptions
    );
    const responseData = await response.json();
    setState((prevState) => ({
      ...prevState,
      csvLink: responseData.url,
      csvLinkGenerated: true,
    }));
  };

  // Link the newly installed feed to the Airtable record ID
  const linkFeedsInstalledToRecordId = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
          app_name: "GoogleFeed",
          shop_id: state.shopId,
          language: state.language,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_LINK_FEEDS_INSTALLED_TO_RECORD_ID,
        requestOptions
      );
      if (!response.ok) {
        console.error("HTTP error! Status: ", response.status);
      }
    } catch (error) {
      console.error("An unexpected error occured: ", error);
    }
  };

  // Filter all the feeds that are installed with the user ID
  const filterFeedsInstalledWithUserId = useCallback(async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_FILTER_FEEDS_INSTALLED_WITH_USER_ID,
        requestOptions
      );
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.data) {
        setState((prevState) => ({
          ...prevState,
          installedFeeds: responseData.data,
        }));
      }
    } catch (error) {
      console.error("An unexpected error occured: ", error);
    }
  }, [idToken]);

  // Handle new submit for a feed installation
  const handleSubmit = async () => {
    const languageError = !state.language;
    setState((prevState) => ({
      ...prevState,
      languageError,
    }));
    if (languageError) {
      return;
    }
    try {
      setState((prevState) => ({
        ...prevState,
        isLoadingCSV: true,
      }));
      await uploadToS3();
      await generateCSV();
      await getCSVLink();
      await linkFeedsInstalledToRecordId();
      setState((prevState) => ({
        ...prevState,
        isLoadingCSV: false,
      }));
    } catch (error) {
      console.error("An error occurred during the submission process:", error);
    }
  };

  // Called twice at start-up, first for the first filter, then second for the second and third filter
  useEffect(() => {
    const checkGoogleFeed = async () => {
      if (!isFirstRender.current) {
        let googleFeedFound = false;
        for (const record of state.records) {
          if (record.fields.AppName === "GoogleFeed") {
            await filterFeedsInstalledWithUserId();
            await filterShopIdWithUserId(idToken, setState, "GoogleFeed");
            googleFeedFound = true;
          }
        }
        if (!googleFeedFound) {
          navigate("/my-apps");
        }
      } else {
        isFirstRender.current = false;
        filterRecordIdWithUserId(idToken, setState);
      }
    };
    checkGoogleFeed();
  }, [
    state.records,
    filterShopIdWithUserId,
    filterFeedsInstalledWithUserId,
    navigate,
    idToken,
  ]);

  // Third useEffect for languages after state.shopIds has updated
  useEffect(() => {
    const getLanguagesForShopIds = async () => {
      const promises = state.shopIds.map(async (shopId) => {
        const languages = await getAvailableLanguages(shopId);
        return { shopId, languages };
      });
      const availableLanguages = await Promise.all(promises);
      console.log("Available languages:", availableLanguages);
      setState((prevState) => ({
        ...prevState,
        availableLanguages,
        googleFeed: true,
      }));
    };
    if (state.shopIds.length > 0) {
      getLanguagesForShopIds();
    }
  }, [state.shopIds]);

  // Copy state.csvLink to clipboard
  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(state.csvLink);
      setState((prevState) => ({
        ...prevState,
        isCopied: true,
      }));
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  // Get the CSV link for installed feeds
  const getInstalledFeedLink = async (shopId, language) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
          shop_id: shopId,
          language: language,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_GET_CSV_LINK,
        requestOptions
      );
      const responseData = await response.json();
      await navigator.clipboard.writeText(responseData.url);
      const resetCopiedButtons = { ...state.copiedButtons };
      for (const key in resetCopiedButtons) {
        resetCopiedButtons[key] = false;
      }
      setState((prevState) => ({
        ...prevState,
        copiedButtons: {
          ...resetCopiedButtons,
          [`${shopId}-${language}`]: true,
        },
      }));
    } catch (error) {
      console.error("An error occurred during the submission process:", error);
    }
  };

  // Go from shop ID select to language select
  const goToLanguageSelect = () => {
    const shopIdError = !state.shopId;
    setState((prevState) => ({
      ...prevState,
      shopIdError,
    }));
    if (shopIdError) {
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        languageSelect: true,
      }));
    }
  };

  // Check if language needs to be disabled
  const isLanguageDisabled = (language) => {
    const isDisabled = state.installedFeeds.some((installedFeed) => {
      const comparison =
        state.shopId === String(installedFeed.shop_id) &&
        language === installedFeed.language;
      return comparison;
    });
    return isDisabled;
  };

  // Check if Shop ID needs to be disabled
  const isShopIdDisabled = (shopId) => {
    const availableLanguagesForShop =
      state.availableLanguages.find(
        (entry) => String(entry.shopId) === String(shopId)
      )?.languages || [];
    const areAllLanguagesInstalled = availableLanguagesForShop.every(
      (language) =>
        state.installedFeeds.some(
          (installedFeed) =>
            String(shopId) === String(installedFeed.shop_id) &&
            language === installedFeed.language
        )
    );
    return areAllLanguagesInstalled;
  };

  // Go back to the original state of the Google Feed
  const goBackToGoogleFeed = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        googleFeed: false,
        csvLinkGenerated: false,
        languageSelect: false,
        shopId: "",
      }));
      await filterFeedsInstalledWithUserId();
    } catch (error) {
      console.error("An unexpected error occured.", error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        googleFeed: true,
      }));
    }
  };

  // Google Feed
  if (state.googleFeed) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {!state.shopIds.every((shopId) => isShopIdDisabled(shopId)) && (
            <Box
              sx={{
                maxWidth: desktopSize ? "500px" : "100%",
                width: "100%",
                minHeight: "200px",
                padding: "40px",
                borderRadius: "8px",
                backgroundColor: theme.palette.grey.main,
              }}
            >
              {state.isLoadingCSV ? (
                <Box display="flex" flexDirection="column" height="100%">
                  <Typography variant="h6">
                    Please wait while we are getting your CSV file ready...
                  </Typography>
                  <Box flex={1} />
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress size={60} />
                  </Box>
                  <Box flex={1} />
                </Box>
              ) : state.csvLinkGenerated ? (
                <Box>
                  <Typography variant="h6">
                    Done! You can copy the CSV link on the button below.
                  </Typography>
                  <Typography variant="h6">
                    The Google Feeds will refresh daily at 01:00.
                  </Typography>
                  <Stack
                    spacing={2}
                    sx={{
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "200px",
                      mt: "30px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        px: "20px",
                        py: "10px",
                        my: "20px",
                        borderRadius: 7,
                        boxShadow: 0,
                        ":hover": {
                          boxShadow: 0,
                          backgroundColor: lightenColor(
                            theme.palette.primary.main,
                            0.7
                          ),
                        },
                      }}
                      onClick={copyLinkToClipboard}
                    >
                      {state.isCopied
                        ? "Copied to clipboard!"
                        : "Copy CSV Link"}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        px: "20px",
                        py: "10px",
                        my: "20px",
                        borderRadius: 7,
                        boxShadow: 0,
                        ":hover": {
                          boxShadow: 0,
                          backgroundColor: lightenColor(
                            theme.palette.primary.main,
                            0.7
                          ),
                        },
                      }}
                      onClick={() => goBackToGoogleFeed()}
                    >
                      Go Back
                    </Button>
                  </Stack>
                </Box>
              ) : state.languageSelect ? (
                <Box>
                  <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                    Add a new Google Feed:
                  </Typography>
                  <FormControl fullWidth error={state.languageError}>
                    <InputLabel id="language-select-label">Language</InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={state.language}
                      label="Language"
                      onChange={handleChange("language")}
                      sx={{ backgroundColor: "white", maxWidth: "250px" }}
                    >
                      {state.availableLanguages
                        .filter(({ shopId }) => String(shopId) === state.shopId)
                        .flatMap(({ languages }) => languages)
                        .map((language, index) =>
                          isLanguageDisabled(language) ? null : (
                            <MenuItem key={index} value={language}>
                              {languageNames[language] || language}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {state.languageError && (
                      <FormHelperText error>
                        Error: Please select a language
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Stack
                    direction="row"
                    spacing="10px"
                    sx={{ marginTop: "20px", maxWidth: "250px" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        px: "20px",
                        py: "10px",
                        width: "50%",
                        boxSizing: "border-box",
                        borderRadius: 7,
                        boxShadow: 0,
                        ":hover": {
                          boxShadow: 0,
                          backgroundColor: lightenColor(
                            theme.palette.primary.main,
                            0.7
                          ),
                        },
                      }}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          languageSelect: false,
                        }))
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        px: "20px",
                        py: "10px",
                        width: "50%",
                        boxSizing: "border-box",
                        borderRadius: 7,
                        boxShadow: 0,
                        ":hover": {
                          boxShadow: 0,
                          backgroundColor: lightenColor(
                            theme.palette.primary.main,
                            0.7
                          ),
                        },
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                    Add a new Google Feed
                  </Typography>
                  <FormControl fullWidth error={state.shopIdError}>
                    <InputLabel id="shopid-select-label">Shop ID</InputLabel>
                    <Select
                      labelId="shopid-select-label"
                      id="shopid-select"
                      value={state.shopId}
                      label="Shop ID"
                      onChange={handleChange("shopId")}
                      sx={{ backgroundColor: "white", maxWidth: "250px" }}
                    >
                      {state.shopIds.map((shopId, index) =>
                        isShopIdDisabled(shopId) ? null : (
                          <MenuItem key={index} value={String(shopId)}>
                            {String(shopId)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {state.shopIdError && (
                      <FormHelperText error>
                        Error: Please select a Shop ID
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      px: "20px",
                      py: "10px",
                      marginTop: "20px",
                      maxWidth: "120px",
                      width: "100%",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={goToLanguageSelect}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {state.installedFeeds.length > 0 && (
            <Box
              sx={{
                maxWidth: desktopSize ? "500px" : "100%",
                width: "100%",
                padding: "40px",
                borderRadius: "8px",
                backgroundColor: theme.palette.blue.main,
              }}
            >
              <Box>
                <Typography variant="h4">My Google Feeds</Typography>
                <List>
                  {state.installedFeeds.map((installedFeed, index) => (
                    <ListItem
                      component="a"
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        flexDirection: { xs: "column", sm: "row" },
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      <Stack direction="column" spacing={1}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <strong>Shop ID:</strong> {installedFeed.shop_id}
                            </React.Fragment>
                          }
                        />
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <strong>Language:</strong>{" "}
                              {languageNames[installedFeed.language] ||
                                installedFeed.language}
                            </React.Fragment>
                          }
                        />
                      </Stack>
                      <Button
                        variant="contained"
                        color="black"
                        sx={{
                          color: "white",
                          px: "20px",
                          py: "10px",
                          borderRadius: 7,
                          boxShadow: 0,
                          ":hover": {
                            boxShadow: 0,
                            backgroundColor: lightenColor(
                              theme.palette.primary.main,
                              0.7
                            ),
                          },
                        }}
                        onClick={() =>
                          getInstalledFeedLink(
                            installedFeed.shop_id,
                            installedFeed.language
                          )
                        }
                      >
                        {state.copiedButtons[
                          `${installedFeed.shop_id}-${installedFeed.language}`
                        ]
                          ? "Copied to clipboard!"
                          : "Copy CSV Link"}
                      </Button>
                    </ListItem>
                  ))}
                </List>
                <Typography variant="h6">
                  The Google Feeds will refresh daily at 01:00.
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
        <Button
          variant="contained"
          sx={{
            px: "20px",
            py: "10px",
            borderRadius: 7,
            my: "20px",
            boxShadow: 0,
            backgroundColor: theme.palette.red.main,
            ":hover": {
              boxShadow: 0,
              backgroundColor: theme.palette.red.light,
            },
          }}
          onClick={() => navigate("/my-apps")}
        >
          Back to My Apps
        </Button>
      </Box>
    );
  }

  // Loading state
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: "auto",
        height: `calc(100vh - 108px)`,
        width: "100%",
      }}
    >
      <CircularProgress size={80} color="red" />
    </Box>
  );
}
