// Language array
export const languageNames = {
  en: "English",
  nl: "Nederlands",
  de: "Deutsch",
  fr: "Français",
  es: "Español",
  us: "English (US)",
  it: "Italiano",
  pt: "Português",
  da: "Dansk",
  tr: "Türkçe",
  sv: "Svenska",
  ru: "Pусский",
  pl: "Polski",
  no: "Norsk",
  el: "ελληνικά",
  fc: "Français (CA)",
  bg: "Български",
};
