import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import filterRecordIdWithUserId from "../functions/filterRecordIdWithUserId";
import GoogleFeedIcon from "../assets/images/google_feed.png";
import TestIcon from "../assets/images/koppelingen.png";
import ProductSyncIcon from "../assets/images/compass.png";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MyApps({ idToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    records: [],
    isLoading: true,
    selectedApp: "",
    installedApps: [],
    uninstalledApps: [],
  });

  // Variables
  const theme = useTheme();
  const appColors = {
    GoogleFeed: theme.palette.blue.main,
    GPT_Content: theme.palette.grey.main,
    ProductSync: theme.palette.yellow.main,
  };
  const lightenColor = (color, amount) => alpha(color, amount);
  const desktopSize = useMediaQuery("(min-width:768px)");
  const isFirstRender = useRef(true);

  // Check what apps are installed and what apps are uninstalled
  useEffect(() => {
    const checkAppsAvailable = async () => {
      if (!isFirstRender.current) {
        const installedAppsSet = new Set(
          state.records.map((record) => record.fields.AppName)
        );
        const installedApps = Array.from(installedAppsSet);
        const allApps = ["GoogleFeed", "GPT_Content", "ProductSync"];
        const uninstalledApps = allApps.filter(
          (app) => !installedAppsSet.has(app)
        );
        setState((prevState) => ({
          ...prevState,
          installedApps: installedApps,
          uninstalledApps: uninstalledApps,
          isLoading: false,
        }));
      } else {
        isFirstRender.current = false;
        filterRecordIdWithUserId(idToken, setState);
      }
    };

    checkAppsAvailable();
  }, [state.records, idToken]);

  // Navigate to an app
  const navigate = useNavigate();
  const handleApp = (appName) => {
    setState((prevState) => ({ ...prevState, selectedApp: appName }));
    if (appName === "GoogleFeed") {
      navigate("/my-apps/google-feed");
    }
    if (appName === "GPT_Content") {
      navigate("/my-apps/content-creator");
    }
    if (appName === "ProductSync") {
      navigate("/my-apps/product-sync");
    }
  };

  // Return the correct icon based on app name
  const getAppIcon = (appName) => {
    switch (appName) {
      case "GoogleFeed":
        return GoogleFeedIcon;
      case "GPT_Content":
        return TestIcon;
      case "ProductSync":
        return ProductSyncIcon;
      default:
        return null;
    }
  };

  // Return display name
  function getDisplayName(AppName) {
    switch (AppName) {
      case "GoogleFeed":
        return "Google Feed";
      case "GPT_Content":
        return "GPT Content";
      default:
        return AppName;
    }
  }

  // Return the correct color based on app name
  const getAppColor = (appName) => {
    return appColors[appName] || theme.palette.grey.main;
  };

  // My Apps
  if (!state.isLoading) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Installed Apps
        </Typography>
        <List
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: 0,
          }}
        >
          {state.installedApps.map((app, index) => (
            <ListItemButton
              component="a"
              key={index}
              onClick={() => handleApp(app)}
              sx={{
                display: "flex",
                flex: "none",
                flexDirection: "column",
                width: desktopSize ? "320px" : "50%",
                padding: "10px",
                borderRadius: "8px",
                ":hover": {
                  backgroundColor: "#fbfaf7",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  padding: desktopSize ? "20px" : "10px",
                  backgroundColor: getAppColor(app),
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: lightenColor(getAppColor(app), 0.7),
                  },
                }}
              >
                <img
                  src={getAppIcon(app)}
                  alt={`${app}-icon`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "0 auto",
                  }}
                />
              </Box>
              <ListItemText
                primary={
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    {getDisplayName(app)}
                  </Typography>
                }
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              />
            </ListItemButton>
          ))}
        </List>
        <Typography variant="h4" sx={{ mt: "40px", mb: "20px" }}>
          Uninstalled Apps
        </Typography>
        <List
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: 0,
          }}
        >
          {state.uninstalledApps.map((app, index) => (
            <ListItemButton
              component="a"
              key={index}
              onClick={() => handleApp(app)}
              sx={{
                display: "flex",
                flex: "none",
                flexDirection: "column",
                width: desktopSize ? "320px" : "50%",
                padding: "10px",
                borderRadius: "8px",
                ":hover": {
                  backgroundColor: "#fbfaf7",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  padding: desktopSize ? "20px" : "10px",
                  backgroundColor: getAppColor(app),
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: lightenColor(getAppColor(app), 0.7),
                  },
                }}
              >
                <img
                  src={getAppIcon(app)}
                  alt={`${app}-icon`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "0 auto",
                  }}
                />
              </Box>
              <ListItemText
                primary={
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    {getDisplayName(app)}
                  </Typography>
                }
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    );
  }

  // Loading state
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: "auto",
        height: `calc(100vh - 108px)`,
        width: "100%",
      }}
    >
      <CircularProgress size={80} color="red" />
    </Box>
  );
}
