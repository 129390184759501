import React, { useState, useEffect, useRef } from "react";
import { Stack, Button, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import filterRecordIdWithUserId from "../functions/filterRecordIdWithUserId";
import useFilterShopIdWithUserId from "../functions/useFilterShopIdWithUserId";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function TemplateApp({ idToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    shopIds: [],
    records: [],
    templateApp: false,
  });

  // Variables
  const filterShopIdWithUserId = useFilterShopIdWithUserId();
  const theme = useTheme();
  const desktopSize = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  // Called twice at start-up, first for the first filter, then second for the second filter
  useEffect(() => {
    const checkTemplateApp = async () => {
      if (!isFirstRender.current) {
        let templateAppFound = false;
        for (const record of state.records) {
          if (record.fields.AppName === "ProductSync") {
            await filterShopIdWithUserId(idToken, setState, "ProductSync");
            templateAppFound = true;
            setState((prevState) => ({
              ...prevState,
              templateApp: true,
            }));
          }
        }

        if (!templateAppFound) {
          navigate("/my-apps");
        }
      } else {
        isFirstRender.current = false;
        filterRecordIdWithUserId(idToken, setState);
      }
    };
    checkTemplateApp();
  }, [state.records, filterShopIdWithUserId, navigate, idToken]);

  //
  //
  // Add new functions here
  //
  //

  // Template App
  if (state.templateApp) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              maxWidth: desktopSize ? "500px" : "100%",
              width: "100%",
              minHeight: "200px",
              padding: "40px",
              borderRadius: "8px",
              backgroundColor: theme.palette.grey.main,
            }}
          ></Box>
        </Stack>
        <Button
          variant="contained"
          sx={{
            px: "20px",
            py: "10px",
            borderRadius: 7,
            my: "20px",
            boxShadow: 0,
            backgroundColor: theme.palette.red.main,
            ":hover": {
              boxShadow: 0,
              backgroundColor: theme.palette.red.light,
            },
          }}
          onClick={() => navigate("/my-apps")}
        >
          Back to My Apps
        </Button>
      </Box>
    );
  }

  // Loading state
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: "auto",
        height: `calc(100vh - 108px)`,
        width: "100%",
      }}
    >
      <CircularProgress size={80} color="red" />
    </Box>
  );
}
