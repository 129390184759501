import { Box, Typography, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Home() {
  // Variables
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();

  // Home
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Stack
          sx={{
            maxWidth: desktopSize ? "1000px" : "100%",
            width: "100%",
            padding: "40px",
            borderRadius: "8px",
            backgroundColor: theme.palette.grey.main,
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h4">Welcome to the Duxly Dashboard!</Typography>
          <Typography variant="body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel
            quam nec nibh fringilla ullamcorper vel non ante. Aenean aliquet mi
            eget sodales mollis. Suspendisse rhoncus hendrerit fermentum.
          </Typography>
          <Typography variant="body">
            Ut id lectus consectetur, accumsan diam sed, cursus neque. Nunc
            metus tellus, iaculis non volutpat non, posuere consectetur urna.
            Integer non ipsum ac quam mollis bibendum. Vivamus auctor pharetra
            mattis. Donec facilisis erat quis leo commodo lacinia. Interdum et
            malesuada fames ac ante ipsum primis in faucibus.
          </Typography>
          <Typography variant="body">
            Mauris ut enim at magna ornare scelerisque in at elit. Pellentesque
            egestas iaculis ipsum at tempus. Nulla eget est ac neque dictum
            faucibus id et lorem. Maecenas aliquam orci quis varius sodales.
            Nunc vulputate euismod lorem, at eleifend purus malesuada in. Mauris
            pulvinar eget elit et ultricies. Nulla faucibus at purus at
            placerat.
          </Typography>
          <Typography variant="body">
            Pellentesque pulvinar orci vitae imperdiet fringilla. Nam sapien ex,
            imperdiet id ex rutrum, commodo ultricies nibh. Donec quis molestie
            risus. Suspendisse iaculis eros non purus ultricies mollis.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
