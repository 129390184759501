import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/fonts/fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Create theme for dashboard with MUI
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    grey: {
      main: "#efebe2",
    },
    black: {
      main: "#000000",
    },
    red: {
      main: "#e53a1c",
      light: "rgb(235, 103, 80)",
    },
    yellow: {
      main: "#f1cd1a",
    },
    blue: {
      main: "#08b4c5",
    },
  },
  typography: {
    fontFamily: "Fira Sans",
    h4: {
      fontFamily: "New Spirit",
      fontWeight: 500,
    },
    button: {
      fontSize: "12pt",
      textTransform: "none",
    },
  },
});

root.render(
  //<React.StrictMode>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
