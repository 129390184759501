// Get all the available languages for the shop ID
export default async function getAvailableLanguages(shopId) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id: shopId,
      }),
    };
    const response = await fetch(
      process.env.REACT_APP_GET_LANGUAGES_WITH_SHOP_ID,
      requestOptions
    );
    const responseData = await response.json();
    const languageCodes = responseData.lightspeedData.languages.map(
      (language) => language.code
    );
    return languageCodes;
  } catch (error) {
    console.error("Error fetching languages for shop ID:", shopId, error);
    return [];
  }
}
