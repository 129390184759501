import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Stack,
  Typography,
  Button,
  OutlinedInput,
  Chip,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ButtonBase,
  Slider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import filterRecordIdWithUserId from "../functions/filterRecordIdWithUserId";
import getAvailableLanguages from "../functions/getAvailableLanguages";
import useFilterShopIdWithUserId from "../functions/useFilterShopIdWithUserId";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha } from "@mui/material/styles";
import { languageNames } from "../variables/languageNames";

export default function ContentCreator({ idToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    shopId: "",
    shopIds: [],
    shopIdError: false,
    records: [],
    contentCreator: false,
    shopForm: false,
    shopFormUploaded: false,
    availableLanguages: [],
    isLoadingForm: false,
    selectedLanguages: [],
    name: "",
    specialty: "",
    url: "",
    html: "",
    context: "",
    keywords: [],
    keywordInput: "",
    audience: "",
    brand: true,
    brand_url: true,
    categories: true,
    metafield: true,
    categories_url: true,
    specifications: true,
    filters: true,
    content: true,
    sliderValue: 0.4,
    installedContentCreator: [],
    editShopForm: false,
  });

  // Variables
  const filterShopIdWithUserId = useFilterShopIdWithUserId();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const theme = useTheme();
  const desktopSize = useMediaQuery("(min-width:768px)");
  const lightenColor = (color, amount) => alpha(color, amount);

  // Handle change for string states
  const handleChange = (key) => (e) => {
    const value = key === "selectedLanguages" ? e.target.value : e.target.value;
    setState((prevState) => ({
      ...prevState,
      [key]: value,
      [`${key}Error`]: false,
    }));
  };

  // Handle change for checkbox state
  const handleCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // Handle change for slider state
  const handleSlider = (newValue) => {
    setState((prevState) => ({
      ...prevState,
      sliderValue: newValue,
    }));
  };

  // Filter all the feeds that are installed with the user ID
  const filterContentCreatorInstalledWithUserId = useCallback(async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_FILTER_CONTENT_CREATOR_INSTALLED_WITH_USER_ID,
        requestOptions
      );
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.data) {
        setState((prevState) => ({
          ...prevState,
          installedContentCreator: responseData.data,
        }));
      }
    } catch (error) {
      console.error("An unexpected error occured: ", error);
    }
  }, [idToken]);

  // Go from shop ID select to shop form
  const goToShopForm = useCallback(() => {
    const shopIdError = !state.shopId;
    setState((prevState) => ({
      ...prevState,
      shopIdError,
    }));
    if (shopIdError) {
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: true,
        contentCreator: false,
      }));
    }
  }, [state.shopId]);

  // Called twice at start-up, first for the first filter, then second for the second and third filter
  useEffect(() => {
    const checkContentCreator = async () => {
      if (!isFirstRender.current) {
        let contentCreatorFound = false;
        for (const record of state.records) {
          if (record.fields.AppName === "GPT_Content") {
            await filterContentCreatorInstalledWithUserId();
            await filterShopIdWithUserId(idToken, setState, "GPT_Content");
            contentCreatorFound = true;
            setState((prevState) => ({
              ...prevState,
              contentCreator: true,
            }));
          }
        }
        if (!contentCreatorFound) {
          navigate("/my-apps");
        }
      } else {
        isFirstRender.current = false;
        filterRecordIdWithUserId(idToken, setState);
      }
    };
    checkContentCreator();
  }, [
    state.records,
    filterShopIdWithUserId,
    filterContentCreatorInstalledWithUserId,
    navigate,
    idToken,
  ]);

  // Called when going to shop form to get available languages
  useEffect(() => {
    const getLanguagesForShopId = async () => {
      const availableLanguages = await getAvailableLanguages(state.shopId);
      setState((prevState) => ({
        ...prevState,
        availableLanguages,
        shopForm: true,
        isLoadingForm: false,
      }));
    };
    if (state.isLoadingForm && state.shopId.length > 0) {
      getLanguagesForShopId();
    }
  }, [state.shopId, state.isLoadingForm]);

  // Trigger goToShopForm when state editShopForm is true
  useEffect(() => {
    if (state.editShopForm) {
      goToShopForm();
      console.log("name: " + state.name);
      setState((prevState) => ({
        ...prevState,
        editShopForm: false,
      }));
    }
  }, [state.editShopForm, state.isLoadingForm, goToShopForm, state.name]);

  // Add a keyword to the keywords array
  const handleAddKeyword = () => {
    if (state.keywordInput && !state.keywords.includes(state.keywordInput)) {
      setState((prevState) => ({
        ...prevState,
        keywords: [...prevState.keywords, prevState.keywordInput],
        keywordInput: "",
      }));
    }
  };

  // Delete a keyword from the keywords array
  const handleDeleteKeyword = (keywordToDelete) => () => {
    setState((prevState) => ({
      ...prevState,
      keywords: prevState.keywords.filter(
        (keyword) => keyword !== keywordToDelete
      ),
    }));
  };

  // Links the newly installed content creator to the Airtable record ID
  const linkContentCreatorInstalledToRecordId = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
          app_name: "GPT_Content",
          shop_id: state.shopId,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_LINK_CONTENT_CREATOR_TO_RECORD_ID,
        requestOptions
      );
      if (!response.ok) {
        console.error("HTTP error! Status: ", response.status);
      }
    } catch (error) {
      console.error("An unexpected error occured: ", error);
    }
  };

  // Post values necessary to upload config.json to S3
  const uploadContentCreatorConfig = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        shopForm: false,
      }));
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
          shop_id: state.shopId,
          selectedLanguages: state.selectedLanguages,
          name: state.name,
          specialty: state.specialty,
          url: state.url,
          html: state.html,
          context: state.context,
          keywords: state.keywords,
          audience: state.audience,
          brand: state.brand,
          brand_url: state.brand_url,
          categories: state.categories,
          metafield: state.metafield,
          categories_url: state.categories_url,
          specifications: state.specifications,
          filters: state.filters,
          content: state.content,
          sliderValue: state.sliderValue,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_UPLOAD_CONTENT_CREATOR_CONFIG,
        requestOptions
      );
      if (!response.ok) {
        console.error("HTTP error! Status: ", response.status);
      }
      await linkContentCreatorInstalledToRecordId();
      setState((prevState) => ({
        ...prevState,
        shopFormUploaded: true,
      }));
    } catch (error) {
      console.error("An error occurred during the submission process: ", error);
    }
  };

  // Get the CSV link for installed feeds
  const fetchContentCreatorDataFromS3 = async (shopId) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_token: idToken,
          shop_id: shopId,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_FETCH_CONTENT_CREATOR_DATA_FROM_S3,
        requestOptions
      );
      const responseData = await response.json();
      setState((prevState) => ({
        ...prevState,
        shopId: responseData.shop.id,
        selectedLanguages: responseData.shop.language_codes,
        name: responseData.shop.name,
        specialty: responseData.shop.specialty,
        url: responseData.shop.url,
        html: responseData.content_settings.html,
        context: responseData.content_settings.context,
        keywords: responseData.content_settings.keywords,
        audience: responseData.content_settings.audience,
        brand: responseData.module_selection.brand,
        brand_url: responseData.module_selection.brand_url,
        categories: responseData.module_selection.categories,
        metafield: responseData.module_selection.metafield,
        categories_url: responseData.module_selection.categories_url,
        specifications: responseData.module_selection.specifications,
        filters: responseData.module_selection.filters,
        content: responseData.module_selection.content,
        sliderValue: responseData.language_model.temperature,
        editShopForm: true,
      }));
    } catch (error) {
      console.error("An error occurred during the submission process: ", error);
    }
  };

  // Go back to the original state of the Content Creator
  const goBackToMyContentCreator = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        shopFormUploaded: false,
      }));
      await filterContentCreatorInstalledWithUserId();
    } catch (error) {
      console.error("An unexpected error occured: ", error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        contentCreator: true,
      }));
    }
  };

  // Check if the shop ID is already installed
  const isShopIdDisabled = (shopId) => {
    const isShopDisabled = state.installedContentCreator.some(
      (installedCC) => String(shopId) === String(installedCC.shop_id)
    );
    return isShopDisabled;
  };

  // Content Creator base state
  if (state.contentCreator) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {!state.shopIds.every((shopId) => isShopIdDisabled(shopId)) && (
            <Stack
              sx={{
                maxWidth: desktopSize ? "500px" : "100%",
                width: "100%",
                minHeight: "200px",
                padding: "40px",
                borderRadius: "8px",
                backgroundColor: theme.palette.grey.main,
                gap: "20px",
              }}
            >
              <Typography variant="h4">Add a new Content Creator</Typography>
              <Typography variant="h6">
                Please choose a shop to create content for.
              </Typography>
              <FormControl fullWidth error={state.shopIdError}>
                <InputLabel id="shopid-select-label">Shop ID</InputLabel>
                <Select
                  labelId="shopid-select-label"
                  id="shopid-select"
                  value={state.shopId}
                  label="Shop ID"
                  onChange={handleChange("shopId")}
                  sx={{ backgroundColor: "white", maxWidth: "250px" }}
                >
                  {state.shopIds.map((shopId, index) => (
                    <MenuItem key={index} value={String(shopId)}>
                      {String(shopId)}
                    </MenuItem>
                  ))}
                </Select>
                {state.shopIdError && (
                  <FormHelperText error>
                    Error: Please select a Shop ID
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  px: "20px",
                  py: "10px",
                  marginTop: "20px",
                  maxWidth: "120px",
                  width: "100%",
                  borderRadius: 7,
                  boxShadow: 0,
                  ":hover": {
                    boxShadow: 0,
                    backgroundColor: lightenColor(
                      theme.palette.primary.main,
                      0.7
                    ),
                  },
                }}
                onClick={goToShopForm}
              >
                Next
              </Button>
            </Stack>
          )}
          {state.installedContentCreator.length > 0 && (
            <Box
              sx={{
                maxWidth: desktopSize ? "500px" : "100%",
                width: "100%",
                padding: "40px",
                borderRadius: "8px",
                backgroundColor: theme.palette.blue.main,
              }}
            >
              <Box>
                <Typography variant="h4">My Content Creator:</Typography>
                <List>
                  {state.installedContentCreator.map((installedCC, index) => (
                    <ListItem
                      component="a"
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: { xs: "column", sm: "row" },
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      <Stack direction="column" spacing={1}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <strong>Shop ID:</strong> {installedCC.shop_id}
                            </React.Fragment>
                          }
                        />
                      </Stack>
                      <Button
                        variant="contained"
                        color="black"
                        sx={{
                          color: "white",
                          px: "20px",
                          py: "10px",
                          borderRadius: 7,
                          boxShadow: 0,
                          ":hover": {
                            boxShadow: 0,
                            backgroundColor: lightenColor(
                              theme.palette.primary.main,
                              0.7
                            ),
                          },
                        }}
                        onClick={() =>
                          fetchContentCreatorDataFromS3(installedCC.shop_id)
                        }
                      >
                        Edit Content Creator
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          )}
        </Stack>
        <Button
          variant="contained"
          sx={{
            px: "20px",
            py: "10px",
            borderRadius: 7,
            my: "20px",
            boxShadow: 0,
            backgroundColor: theme.palette.red.main,
            ":hover": {
              boxShadow: 0,
              backgroundColor: theme.palette.red.light,
            },
          }}
          onClick={() => navigate("/my-apps")}
        >
          Back to My Apps
        </Button>
      </Box>
    );
  }

  // The shop form when uploading or editing a shop
  if (state.shopForm) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Stack
            sx={{
              maxWidth: desktopSize ? "500px" : "100%",
              width: "100%",
              minHeight: "200px",
              padding: "40px",
              borderRadius: "8px",
              backgroundColor: theme.palette.grey.main,
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h4">
              Fill in the Content Creator form
            </Typography>
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              Select the languages you want to create content for.
            </Typography>
            <FormControl>
              <InputLabel>Languages</InputLabel>
              <Select
                multiple
                value={state.selectedLanguages}
                onChange={handleChange("selectedLanguages")}
                input={<OutlinedInput label="Languages" />}
                sx={{ backgroundColor: "white" }}
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Stack>
                )}
              >
                {state.availableLanguages.map((language) => (
                  <MenuItem
                    key={languageNames[language] || language}
                    value={languageNames[language] || language}
                  >
                    {languageNames[language] || language}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              Name of the shop.
            </Typography>
            <TextField
              type="text"
              label="Name"
              value={state.name}
              InputProps={{
                sx: { backgroundColor: "white" },
              }}
              onChange={handleChange("name")}
            />
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              Specialty of the shop.
            </Typography>
            <TextField
              type="text"
              label="Specialty"
              value={state.specialty}
              InputProps={{
                sx: { backgroundColor: "white" },
              }}
              onChange={handleChange("specialty")}
            />
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              The URL of the webshop.
            </Typography>
            <TextField
              type="url"
              label="Website URL"
              value={state.url}
              InputProps={{
                sx: { backgroundColor: "white" },
              }}
              onChange={handleChange("url")}
            />
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              Is there need for HTML in the content, or does there need to be a
              focus on specific tags?
            </Typography>
            <TextField
              id="html"
              label="HTML"
              value={state.html}
              multiline
              maxRows={6}
              InputProps={{
                sx: { backgroundColor: "white" },
              }}
              onChange={handleChange("html")}
            />
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              What does ChatGPT need to do?
            </Typography>
            <TextField
              id="context"
              label="Context"
              value={state.context}
              multiline
              maxRows={6}
              InputProps={{
                sx: { backgroundColor: "white" },
              }}
              onChange={handleChange("context")}
            />
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              What keywords do the products need to contain?
            </Typography>
            <Stack
              direction="row"
              sx={{
                gap: "20px",
                width: "100%",
                display: "flex",
                marginBottom: "-20px",
              }}
            >
              <TextField
                label="Add Keyword"
                variant="outlined"
                value={state.keywordInput}
                onChange={handleChange("keywordInput")}
                sx={{
                  flexGrow: 1,
                  width: "calc(50% - 10px)",
                }}
                InputProps={{
                  sx: { width: "100%", backgroundColor: "white" },
                }}
              />
              <Button
                onClick={handleAddKeyword}
                variant="contained"
                sx={{
                  flexGrow: 1,
                  width: "calc(50% - 10px)",
                  px: "20px",
                  py: "10px",
                  boxSizing: "border-box",
                  borderRadius: 7,
                  boxShadow: 0,
                  ":hover": {
                    boxShadow: 0,
                    backgroundColor: "lighten(theme.palette.primary.main, 0.7)",
                  },
                }}
              >
                Add Keyword
              </Button>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                border: 1,
                borderColor: "grey.400",
                backgroundColor: "white",
                borderRadius: 1,
                padding: 1,
                minHeight: "32px",
                "&:hover": {
                  borderColor: "primary.main",
                },
                ":focus-within": {
                  borderColor: "primary.main",
                },
              }}
            >
              {state.keywords.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword}
                  onDelete={handleDeleteKeyword(keyword)}
                />
              ))}
            </Box>
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              For what kind of public are the products?
            </Typography>
            <TextField
              id="audience"
              label="Audience"
              value={state.audience}
              multiline
              maxRows={6}
              InputProps={{
                sx: { backgroundColor: "white" },
              }}
              onChange={handleChange("audience")}
            />
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              What kind of modules need to be added to the prompt?
            </Typography>
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: desktopSize ? "row" : "column",
              }}
            >
              <FormGroup sx={{ width: "50%" }}>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["brand"]}
                        onChange={handleCheckbox}
                        name="brand"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Brand"
                  />
                </ButtonBase>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["brand_url"]}
                        onChange={handleCheckbox}
                        name="brand_url"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Brand Url"
                  />
                </ButtonBase>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["categories"]}
                        onChange={handleCheckbox}
                        name="categories"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Categories"
                  />
                </ButtonBase>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["metafield"]}
                        onChange={handleCheckbox}
                        name="metafield"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Metafield"
                  />
                </ButtonBase>
              </FormGroup>
              <FormGroup sx={{ width: "50%" }}>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["categories_url"]}
                        onChange={handleCheckbox}
                        name="categories_url"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Categories Url"
                  />
                </ButtonBase>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["specifications"]}
                        onChange={handleCheckbox}
                        name="specifications"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Specifications"
                  />
                </ButtonBase>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["filters"]}
                        onChange={handleCheckbox}
                        name="filters"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Filters"
                  />
                </ButtonBase>
                <ButtonBase
                  component="label"
                  sx={{
                    display: "block",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state["content"]}
                        onChange={handleCheckbox}
                        name="content"
                        sx={{
                          marginLeft: "20px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Content"
                  />
                </ButtonBase>
              </FormGroup>
            </Stack>
            <Typography variant="body" sx={{ marginBottom: "-20px" }}>
              How creative does ChatGPT need to be with the text? 0 is not
              creative and 2 is extremely creative.
            </Typography>
            <Slider
              aria-label="Custom step slider"
              value={state.sliderValue}
              onChange={(_, newValue) => handleSlider(newValue)}
              step={0.1}
              marks
              min={0}
              max={2}
              valueLabelDisplay="auto"
            />
            <Stack
              direction="row"
              sx={{ width: "100%", display: "flex", gap: "20px" }}
            >
              <Button
                variant="contained"
                sx={{
                  px: "20px",
                  py: "10px",
                  width: "50%",
                  boxSizing: "border-box",
                  borderRadius: 7,
                  boxShadow: 0,
                  ":hover": {
                    boxShadow: 0,
                    backgroundColor: lightenColor(
                      theme.palette.primary.main,
                      0.7
                    ),
                  },
                }}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    shopForm: false,
                    contentCreator: true,
                  }))
                }
              >
                Previous
              </Button>
              <Button
                variant="contained"
                sx={{
                  px: "20px",
                  py: "10px",
                  width: "50%",
                  boxSizing: "border-box",
                  borderRadius: 7,
                  boxShadow: 0,
                  ":hover": {
                    boxShadow: 0,
                    backgroundColor: lightenColor(
                      theme.palette.primary.main,
                      0.7
                    ),
                  },
                }}
                onClick={() => uploadContentCreatorConfig()}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  }

  // Message that shop form has been succesfully uploaded or edited
  if (state.shopFormUploaded) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Stack
            sx={{
              maxWidth: desktopSize ? "500px" : "100%",
              width: "100%",
              minHeight: "200px",
              padding: "40px",
              borderRadius: "8px",
              backgroundColor: theme.palette.grey.main,
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Box>
              <Typography variant="h6">
                Content Creator uploaded! Click below to go back to your content
                creator.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  px: "20px",
                  py: "10px",
                  my: "20px",
                  borderRadius: 7,
                  boxShadow: 0,
                  ":hover": {
                    boxShadow: 0,
                    backgroundColor: lightenColor(
                      theme.palette.primary.main,
                      0.7
                    ),
                  },
                }}
                onClick={() => goBackToMyContentCreator()}
              >
                Go Back
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Box>
    );
  }

  // Loading state
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: "auto",
        height: `calc(100vh - 108px)`,
        width: "100%",
      }}
    >
      <CircularProgress size={80} color="red" />
    </Box>
  );
}
