import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {
  Box,
  Stack,
  Typography,
  Link,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import duxlylogo from "../assets/images/duxly-logo-zwart.png";
import compass from "../assets/images/compass.png";
import BoxStyle from "../styles/BoxStyle";
import validatePasswordCriteria from "../functions/validatePasswordCriteria";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function PasswordReset() {
  // Manage all the different states
  const [state, setState] = useState({
    username: "",
    confirmationCode: "",
    newPassword: "",
    newConfirmPassword: "",
    passwordReset: true,
    confirmPasswordReset: false,
    successPasswordReset: false,
    invalidEmail: false,
    passwordError: false,
    passwordMismatch: false,
    codeMismatch: false,
    unexpectedError: false,
    confirmationCodeIsInvalid: false,
  });

  // Variables
  const urlParams = new URLSearchParams(window.location.search);
  const record_id = urlParams.get("record_id");
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();
  const navigate = useNavigate();

  // Handle change for all inputs
  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };

  // Send confirmation code
  const sendConfirmationCode = async () => {
    const emailIsValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
      state.username
    );
    if (emailIsValid) {
      try {
        await Auth.forgotPassword(state.username);
        setState({
          ...state,
          passwordReset: false,
          confirmPasswordReset: true,
          unexpectedError: false,
          invalidEmail: false,
        });
      } catch (error) {
        setState({
          ...state,
          unexpectedError: true,
          invalidEmail: false,
        });
      }
    } else {
      setState({
        ...state,
        unexpectedError: false,
        invalidEmail: !emailIsValid,
      });
    }
  };

  // Handle confirm new password
  const handleConfirmNewPassword = async () => {
    const passwordMeetsCriteria = validatePasswordCriteria(state.newPassword);
    const passwordIdentical = state.newPassword === state.newConfirmPassword;
    const confirmationCodeIsValid = /^\d{6}$/.test(state.confirmationCode);
    if (passwordMeetsCriteria && passwordIdentical && confirmationCodeIsValid) {
      try {
        await Auth.forgotPasswordSubmit(
          state.username,
          state.confirmationCode,
          state.newPassword
        );
        setState({
          ...state,
          confirmPasswordReset: false,
          successPasswordReset: true,
          unexpectedError: false,
          codeMismatch: false,
          passwordError: false,
          passwordMismatch: false,
          confirmationCodeIsInvalid: false,
        });
      } catch (error) {
        if (error.code === "CodeMismatchException") {
          setState({
            ...state,
            unexpectedError: false,
            codeMismatch: true,
            passwordError: false,
            passwordMismatch: false,
            confirmationCodeIsInvalid: false,
          });
        } else {
          setState({
            ...state,
            unexpectedError: true,
            codeMismatch: false,
            passwordError: false,
            passwordMismatch: false,
            confirmationCodeIsInvalid: false,
          });
        }
      }
    } else {
      setState({
        ...state,
        unexpectedError: false,
        codeMismatch: false,
        passwordError: !passwordMeetsCriteria,
        passwordMismatch: !passwordIdentical,
        confirmationCodeIsInvalid: !confirmationCodeIsValid,
      });
    }
  };

  // Navigate to Login
  const toLogin = () => {
    if (record_id) {
      navigate("/login?record_id=" + record_id);
    } else {
      navigate("/login");
    }
  };

  // Navigate to Register
  const toRegister = () => {
    if (record_id) {
      navigate("/register?record_id=" + record_id);
    } else {
      navigate("/register");
    }
  };

  // Password Reset
  return (
    <Stack direction={desktopSize ? "row" : "column"} spacing={2} margin={0}>
      <Box sx={{ ...BoxStyle, maxWidth: desktopSize ? "60%" : "100%" }}>
        <Stack alignItems="left" sx={{ width: "500px" }}>
          <Box sx={{ maxWidth: "300px", px: "30px", py: "30px" }}>
            <img src={duxlylogo} alt="logo" />
          </Box>
          <Box sx={{ px: "30px", py: "30px" }}>
            {state.passwordReset && (
              <Stack spacing={2}>
                <Typography variant="body">
                  Fill in your email address:
                </Typography>
                <TextField
                  type="text"
                  label="Email"
                  onChange={handleChange("username")}
                  error={state.invalidEmail}
                  helperText={
                    state.invalidEmail ? "Not a valid email address." : ""
                  }
                />
                <Button
                  onClick={sendConfirmationCode}
                  variant="contained"
                  size="large"
                  sx={{
                    px: "20px",
                    py: "10px",
                    borderRadius: 7,
                    boxShadow: 0,
                    backgroundColor: theme.palette.red.main,
                    ":hover": {
                      boxShadow: 0,
                      backgroundColor: theme.palette.red.light,
                    },
                  }}
                >
                  Send Confirmation Code
                </Button>
                {state.unexpectedError && (
                  <Alert severity="error">An unexpected error occured.</Alert>
                )}
              </Stack>
            )}
            {state.confirmPasswordReset && (
              <Stack spacing={2}>
                <Typography variant="body">
                  Enter the code sent to your email and a new password:
                </Typography>
                <TextField
                  type="text"
                  label="Confirmation Code"
                  onChange={handleChange("confirmationCode")}
                  error={state.confirmationCodeIsInvalid}
                  helperText={
                    state.confirmationCodeIsInvalid
                      ? "Confirmation code must be numbers and has to be 6 characters long."
                      : ""
                  }
                />
                <TextField
                  type="password"
                  label="New Password"
                  onChange={handleChange("newPassword")}
                  error={state.passwordError}
                  helperText={
                    state.passwordError
                      ? "Password must contain at least 1 number, 1 special character, 1 lowercase letter, 1 uppercase letter, and has to be at least 8 characters long."
                      : ""
                  }
                />
                <TextField
                  type="password"
                  label="Confirm New Password"
                  onChange={handleChange("newConfirmPassword")}
                  error={state.passwordMismatch}
                  helperText={
                    state.passwordMismatch ? "Passwords do not match!" : ""
                  }
                />
                <Button
                  onClick={handleConfirmNewPassword}
                  variant="contained"
                  size="large"
                  sx={{
                    px: "20px",
                    py: "10px",
                    borderRadius: 7,
                    boxShadow: 0,
                    backgroundColor: theme.palette.red.main,
                    ":hover": {
                      boxShadow: 0,
                      backgroundColor: theme.palette.red.light,
                    },
                  }}
                >
                  Confirm New Password
                </Button>
                {state.codeMismatch && (
                  <Alert severity="error">
                    Invalid verification code provided.
                  </Alert>
                )}
                {state.unexpectedError && (
                  <Alert severity="error">An unexpected error occured.</Alert>
                )}
              </Stack>
            )}
            {state.successPasswordReset && (
              <Stack spacing={2}>
                <Typography variant="body">
                  You successfully reset your password!
                </Typography>
                <Button onClick={toLogin} variant="contained" size="large">
                  Go back to login
                </Button>
              </Stack>
            )}
          </Box>
          <Typography variant="body" sx={{ px: "30px", py: "30px" }}>
            Don&apos;t have an account? &nbsp;
            <Link
              onClick={toRegister}
              underline="hover"
              style={{ cursor: "pointer", color: theme.palette.red.main }}
            >
              Register here!
            </Link>
          </Typography>
        </Stack>
      </Box>
      {desktopSize && (
        <Box
          sx={{
            ...BoxStyle,
            backgroundColor: "#e53a1c",
            maxWidth: "40%",
          }}
        >
          <Box
            sx={{
              flex: "auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              maxWidth: "600px",
            }}
          >
            <img src={compass} alt="compass" />
          </Box>
        </Box>
      )}
    </Stack>
  );
}
