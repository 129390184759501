// Filter record ID with user ID in Airtable
export default async function filterRecordIdWithUserId(idToken, setState) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id_token: idToken }),
    };
    const response = await fetch(
      process.env.REACT_APP_FILTER_RECORD_ID_WITH_USER_ID,
      requestOptions
    );
    const responseData = await response.json();
    const uniqueRecords = responseData.data.records.reduce(
      (record, current) => {
        const uniqueRecord = record.find(
          (item) => item.fields.AppName === current.fields.AppName
        );
        if (!uniqueRecord) {
          return record.concat([current]);
        } else {
          return record;
        }
      },
      []
    );
    uniqueRecords.sort((a, b) =>
      a.fields.AppName.localeCompare(b.fields.AppName)
    );
    setState((prevState) => ({
      ...prevState,
      records: uniqueRecords,
    }));
  } catch (error) {
    console.log("Error:", error);
  }
}
