import {
  Stack,
  Button,
  Box,
  IconButton,
  SvgIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";

export default function Header({ handleLogout, handleDrawer, idToken }) {
  // Variables
  const { decodedToken, isExpired } = useJwt(idToken);
  const username =
    decodedToken && !isExpired ? decodedToken.email : "Unavailable";
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorElement, setAnchorElement] = useState(null);
  const isMenuOpen = Boolean(anchorElement);

  // Handle Menu selection
  const handleMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  // Handle closing Menu
  const handleClose = () => {
    setAnchorElement(null);
  };

  // Navigate to My Account
  const handleMyAccount = () => {
    navigate("/my-account");
  };

  // Header component
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      sx={{ py: "30px" }}
    >
      {!desktopSize && (
        <IconButton onClick={handleDrawer}>
          <SvgIcon fontSize="small">
            <Bars3Icon />
          </SvgIcon>
        </IconButton>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {desktopSize ? username : ""}
      <Button
        variant="contained"
        color="primary"
        sx={{
          px: "20px",
          py: "12px",
          borderRadius: 7,
          boxShadow: 0,
          backgroundColor: theme.palette.red.main,
          ":hover": {
            boxShadow: 0,
            backgroundColor: theme.palette.red.light,
          },
        }}
        onClick={handleMenu}
      >
        <AccountCircleIcon />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleClose}
        sx={{
          mt: "10px",
        }}
      >
        <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </Stack>
  );
}
