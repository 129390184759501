import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {
  Box,
  Stack,
  Typography,
  Link,
  TextField,
  Alert,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import duxlylogo from "../assets/images/duxly-logo-zwart.png";
import compass from "../assets/images/compass.png";
import { useNavigate } from "react-router-dom";
import BoxStyle from "../styles/BoxStyle";
import validatePasswordCriteria from "../functions/validatePasswordCriteria";
import { useTheme } from "@mui/material/styles";

export default function Login({ setIdToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    username: "",
    password: "",
    loginError: false,
    invalidEmail: false,
    unexpectedError: false,
    emptyPassword: false,
  });

  // Variables
  const urlParams = new URLSearchParams(window.location.search);
  const record_id = urlParams.get("record_id");
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();
  const navigate = useNavigate();

  // Handle change for string states
  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };

  // Navigate to Password Reset
  const toPasswordReset = () => {
    if (record_id) {
      navigate("/password-reset?record_id=" + record_id);
    } else {
      navigate("/password-reset");
    }
  };

  // Navigate to Register
  const toRegister = () => {
    if (record_id) {
      navigate("/register?record_id=" + record_id);
    } else {
      navigate("/register");
    }
  };

  // Link the user ID to the Airtable Record ID
  const linkUserIdToRecordId = async (id_token) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          record_id: record_id,
          id_token: id_token,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_LINK_USER_ID_TO_RECORD_ID,
        requestOptions
      );
      if (!response.ok) {
        console.error("HTTP error! Status: ", response.status);
      }
    } catch (error) {
      setState({ ...state, unexpectedError: true });
    }
  };

  // Handle logging in
  const handleLogin = async () => {
    const emailIsValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
      state.username
    );
    const passwordMeetsCriteria = validatePasswordCriteria(state.password);
    if (emailIsValid && passwordMeetsCriteria) {
      try {
        await Auth.signIn(state.username, state.password);
        const data = await Auth.currentSession();
        const idToken = data.getIdToken().getJwtToken();
        setIdToken(idToken);
        setState({
          ...state,
          loginError: false,
          unexpectedError: false,
          invalidEmail: false,
          emptyPassword: false,
        });
        if (record_id) {
          await linkUserIdToRecordId(idToken);
        }
        navigate("/");
      } catch (error) {
        if (error.code === "NotAuthorizedException") {
          setState({
            ...state,
            loginError: true,
            unexpectedError: false,
            invalidEmail: false,
            emptyPassword: false,
          });
        } else if (error.code === "UserNotConfirmedException") {
          if (record_id) {
            navigate("/register?record_id=" + record_id, {
              state: {
                askForConfirmationCode: true,
                username: state.username,
                password: state.password,
              },
            });
          } else {
            navigate("/register", {
              state: {
                askForConfirmationCode: true,
                username: state.username,
                password: state.password,
              },
            });
          }
        } else {
          setState({
            ...state,
            unexpectedError: true,
            loginError: false,
            invalidEmail: false,
            emptyPassword: false,
          });
        }
      }
    } else {
      setState({
        ...state,
        loginError: false,
        unexpectedError: false,
        invalidEmail: !emailIsValid,
        emptyPassword: !passwordMeetsCriteria,
      });
    }
  };

  // Login page
  return (
    <Stack direction={desktopSize ? "row" : "column"} spacing={2} margin={0}>
      <Box sx={{ ...BoxStyle, maxWidth: desktopSize ? "60%" : "100%" }}>
        <Stack alignItems="left" sx={{ width: "500px" }}>
          <Box sx={{ maxWidth: "300px", px: "30px", py: "30px" }}>
            <img src={duxlylogo} alt="logo" />
          </Box>
          <Box sx={{ px: "30px", py: "30px" }}>
            <Stack spacing={2}>
              <Typography variant="h4">Welcome!</Typography>
              <Typography variant="body">
                Enter your account details below to log in.
              </Typography>
              <TextField
                type="text"
                label="Email"
                onChange={handleChange("username")}
                error={state.invalidEmail}
                helperText={
                  state.invalidEmail ? "Not a valid email address." : ""
                }
              />
              <TextField
                type="password"
                label="Password"
                onChange={handleChange("password")}
                error={state.emptyPassword}
                helperText={state.emptyPassword ? "Not a valid password." : ""}
              />
              <Link
                onClick={toPasswordReset}
                underline="hover"
                color="#AFAFAF"
                variant="body2"
                style={{ marginTop: "5px", cursor: "pointer" }}
              >
                Forgot Password?
              </Link>
              <Button
                onClick={handleLogin}
                variant="contained"
                size="large"
                sx={{
                  px: "20px",
                  py: "10px",
                  borderRadius: 7,
                  boxShadow: 0,
                  backgroundColor: theme.palette.red.main,
                  ":hover": {
                    boxShadow: 0,
                    backgroundColor: theme.palette.red.light,
                  },
                }}
              >
                Login
              </Button>
              {state.loginError && (
                <Alert severity="error">Incorrect username or password!</Alert>
              )}
              {state.unexpectedError && (
                <Alert severity="error">An unexpected error occured.</Alert>
              )}
            </Stack>
          </Box>
          <Typography variant="body" sx={{ px: "30px", py: "30px" }}>
            Don&apos;t have an account? &nbsp;
            <Link
              onClick={toRegister}
              underline="hover"
              sx={{ cursor: "pointer", color: theme.palette.red.main }}
            >
              Register here!
            </Link>
          </Typography>
        </Stack>
      </Box>
      {desktopSize && (
        <Box
          sx={{
            ...BoxStyle,
            backgroundColor: "#e53a1c",
            maxWidth: "40%",
          }}
        >
          <Box
            sx={{
              flex: "auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              maxWidth: "600px",
            }}
          >
            <img src={compass} alt="compass" />
          </Box>
        </Box>
      )}
    </Stack>
  );
}
