import {
  Box,
  Stack,
  Button,
  Typography,
  TextField,
  Alert,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useJwt } from "react-jwt";
import { alpha } from "@mui/material/styles";
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import validatePasswordCriteria from "../functions/validatePasswordCriteria";
import CircularProgress from "@mui/material/CircularProgress";

export default function MyAccount({ idToken, setIdToken }) {
  // Manage all the different states
  const [state, setState] = useState({
    myAccount: true,
    emailChange: false,
    emailChangeSuccesful: false,
    newEmail: "",
    unexpectedError: false,
    invalidEmail: false,
    confirmationNewEmail: false,
    confirmationCode: "",
    confirmationCodeIsInvalid: false,
    codeMismatch: false,
    passwordChange: false,
    newPassword: "",
    confirmNewPassword: "",
    passwordError: false,
    passwordMismatch: false,
    password: "",
    passwordVerificationError: false,
  });

  // Variables
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();
  const { decodedToken, isExpired } = useJwt(idToken);
  const username = decodedToken && !isExpired ? decodedToken.email : "";
  const lightenColor = (color, amount) => alpha(color, amount);

  // Handle change for string states
  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };

  // Set My Account to Change Email state
  const changeEmail = () => {
    setState((prevState) => ({
      ...prevState,
      myAccount: false,
      emailChange: true,
      password: "",
      passwordVerificationError: false,
    }));
  };

  // Set My Account to Change Password state
  const changePassword = () => {
    setState((prevState) => ({
      ...prevState,
      myAccount: false,
      passwordChange: true,
      password: "",
      passwordVerificationError: false,
    }));
  };

  // Go back to My Account state
  const goBackToMyAccount = () => {
    setState((prevState) => ({
      ...prevState,
      myAccount: true,
      emailChange: false,
      emailConfirmationCode: false,
      emailChangeSuccesful: false,
      passwordChange: false,
      passwordChangeSuccesful: false,
    }));
  };

  // Verify password for email change
  const verifyPasswordForEmailChange = async () => {
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.signIn(user.username, state.password);
      submitEmailChange();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        passwordVerificationError: true,
      }));
    }
  };

  // Submit the email change
  const submitEmailChange = async () => {
    const emailIsValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
      state.newEmail
    );
    if (emailIsValid) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          email: state.newEmail,
        });
        setState({
          ...state,
          emailChange: false,
          confirmationNewEmail: true,
        });
        console.log(state.confirmationNewEmail);
      } catch (error) {
        setState({
          ...state,
          unexpectedError: true,
        });
      }
    } else {
      setState({
        ...state,
        invalidEmail: !emailIsValid,
      });
    }
  };

  // Await confirmation code and confirm email change
  const handleConfirmEmailChange = async () => {
    const confirmationCodeIsValid = /^\d{6}$/.test(state.confirmationCode);
    if (confirmationCodeIsValid) {
      try {
        await Auth.verifyCurrentUserAttributeSubmit(
          "email",
          state.confirmationCode
        );
        setTimeout(async () => {
          await Auth.currentAuthenticatedUser({ bypassCache: true });
          const session = await Auth.currentSession();
          const newIdToken = session.getIdToken().getJwtToken();
          setIdToken(newIdToken);
        }, 2000);
        setState({
          ...state,
          codeMismatch: false,
          unexpectedError: false,
          confirmationCodeIsInvalid: false,
          confirmationNewEmail: false,
          emailChangeSuccesful: true,
        });
      } catch (error) {
        if (error.code === "CodeMismatchException") {
          setState({
            ...state,
            codeMismatch: true,
            unexpectedError: false,
            confirmationCodeIsInvalid: false,
          });
        } else {
          setState({
            ...state,
            codeMismatch: false,
            unexpectedError: true,
            confirmationCodeIsInvalid: false,
          });
        }
      }
    } else {
      setState({
        ...state,
        confirmationCodeIsInvalid: !confirmationCodeIsValid,
        codeMismatch: false,
        unexpectedError: false,
      });
    }
  };

  // Verify password for password change
  const verifyPasswordForNewPassword = async () => {
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.signIn(user.username, state.password);
      submitNewPassword();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        passwordVerificationError: true,
        passwordError: false,
        passwordMismatch: false,
      }));
    }
  };

  // Submit the password change
  const submitNewPassword = async () => {
    const passwordMeetsCriteria = validatePasswordCriteria(state.newPassword);
    const passwordIdentical = state.newPassword === state.confirmNewPassword;
    if (passwordMeetsCriteria && passwordIdentical) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, state.password, state.newPassword);
        setState({
          ...state,
          passwordChange: false,
          passwordChangeSuccesful: true,
        });
      } catch (error) {
        setState({
          ...state,
          unexpectedError: true,
        });
      }
    } else {
      setState({
        ...state,
        passwordError: !passwordMeetsCriteria,
        passwordMismatch: !passwordIdentical,
      });
    }
  };

  // My Account page
  if (username) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Stack
            sx={{
              maxWidth: desktopSize ? "500px" : "100%",
              width: "100%",
              minHeight: "360px",
              padding: "40px",
              borderRadius: "8px",
              backgroundColor: theme.palette.grey.main,
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            {state.myAccount && (
              <Box>
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  My Account
                </Typography>
                <Typography>
                  <strong>Email:</strong> {username}
                </Typography>
                <Stack
                  spacing={2}
                  sx={{
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "200px",
                    mt: "30px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => changeEmail()}
                  >
                    Change Email
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => changePassword()}
                  >
                    Change Password
                  </Button>
                </Stack>
              </Box>
            )}
            {state.emailChange && (
              <Stack spacing={2}>
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  Change email
                </Typography>
                <TextField
                  type="password"
                  placeholder="Password"
                  onChange={handleChange("password")}
                  error={state.passwordVerificationError}
                  helperText={
                    state.passwordVerificationError ? "Incorrect password." : ""
                  }
                  sx={{
                    "& input": {
                      backgroundColor: "white",
                    },
                  }}
                />
                <TextField
                  type="text"
                  placeholder="New Email"
                  onChange={handleChange("newEmail")}
                  error={state.invalidEmail}
                  helperText={
                    state.invalidEmail ? "Not a valid email address." : ""
                  }
                  sx={{
                    "& input": {
                      backgroundColor: "white",
                    },
                  }}
                />
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => goBackToMyAccount()}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={verifyPasswordForEmailChange}
                  >
                    Submit
                  </Button>
                </Stack>
                {state.unexpectedError && (
                  <Alert severity="error">An unexpected error occured.</Alert>
                )}
              </Stack>
            )}
            {state.confirmationNewEmail && (
              <Stack spacing={2}>
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  Confirm new email
                </Typography>
                <TextField
                  type="text"
                  placeholder="Confirmation Code"
                  onChange={handleChange("confirmationCode")}
                  error={state.confirmationCodeIsInvalid}
                  helperText={
                    state.confirmationCodeIsInvalid
                      ? "Confirmation code must be numbers and has to be 6 characters long."
                      : ""
                  }
                  sx={{
                    "& input": {
                      backgroundColor: "white",
                    },
                  }}
                />
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => goBackToMyAccount()}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => handleConfirmEmailChange()}
                  >
                    Submit
                  </Button>
                </Stack>
                {state.unexpectedError && (
                  <Alert severity="error">An unexpected error occured.</Alert>
                )}
              </Stack>
            )}
            {state.emailChangeSuccesful && (
              <Stack spacing={2}>
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  Email updated!
                </Typography>
                <Typography>Click below to go back to My Account.</Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => goBackToMyAccount()}
                  >
                    Go Back
                  </Button>
                </Stack>
              </Stack>
            )}
            {state.passwordChange && (
              <Stack spacing={2}>
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  Password change
                </Typography>
                <TextField
                  type="password"
                  placeholder="Password"
                  onChange={handleChange("password")}
                  error={state.passwordVerificationError}
                  helperText={
                    state.passwordVerificationError ? "Incorrect password." : ""
                  }
                  sx={{
                    "& input": {
                      backgroundColor: "white",
                    },
                  }}
                />
                <TextField
                  type="password"
                  placeholder="New Password"
                  onChange={handleChange("newPassword")}
                  error={state.passwordError}
                  helperText={
                    state.passwordError
                      ? "Password must contain at least 1 number, 1 special character, 1 lowercase letter, 1 uppercase letter, and has to be at least 8 characters long."
                      : ""
                  }
                  sx={{
                    "& input": {
                      backgroundColor: "white",
                    },
                  }}
                />
                <TextField
                  type="password"
                  placeholder="Confirm New Password"
                  onChange={handleChange("confirmNewPassword")}
                  error={state.passwordMismatch}
                  helperText={
                    state.passwordMismatch ? "Passwords do not match!" : ""
                  }
                  sx={{
                    "& input": {
                      backgroundColor: "white",
                    },
                  }}
                />
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => goBackToMyAccount()}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => verifyPasswordForNewPassword()}
                  >
                    Submit
                  </Button>
                </Stack>
                {state.unexpectedError && (
                  <Alert severity="error">An unexpected error occured.</Alert>
                )}
              </Stack>
            )}
            {state.passwordChangeSuccesful && (
              <Stack spacing={2}>
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  Password updated!
                </Typography>
                <Typography>Click below to go back to My Account.</Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: "20px",
                      py: "10px",
                      borderRadius: 7,
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                        backgroundColor: lightenColor(
                          theme.palette.primary.main,
                          0.7
                        ),
                      },
                    }}
                    onClick={() => goBackToMyAccount()}
                  >
                    Go Back
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  }

  // Loading state
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: "auto",
        height: `calc(100vh - 108px)`,
        width: "100%",
      }}
    >
      <CircularProgress size={80} color="red" />
    </Box>
  );
}
