// Validate Password
export default function validatePasswordCriteria(password) {
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const isAtLeastEightChars = password.length >= 8;
  return (
    hasNumber &&
    hasSpecialChar &&
    hasLowerCase &&
    hasUpperCase &&
    isAtLeastEightChars
  );
}
