const BoxStyle = {
  backgroundColor: "background.paper",
  flex: "auto",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "100vh",
};

export default BoxStyle;
