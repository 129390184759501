import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Drawer,
  Typography,
} from "@mui/material";
import duxlylogo from "../assets/images/duxly-logo-gebroken-wit.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function DrawerComponent({ drawerOpen, handleDrawer }) {
  // Variables
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
    if (drawerOpen) {
      handleDrawer();
    }
  };
  const navigateToHome = () => navigateTo("/");
  const navigateToMyApps = () => navigateTo("/my-apps");
  const navigateToSupport = () => {
    window.location.href = "https://en.duxly.nl/support";
  };
  const desktopSize = useMediaQuery("(min-width:768px)");
  const theme = useTheme();

  // Drawer component
  return (
    <Box>
      {desktopSize ? (
        <Drawer
          sx={{
            width: "240px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "240px",
              backgroundColor: theme.palette.red.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box sx={{ px: "30px", py: "30px" }}>
            <img src={duxlylogo} alt="logo" />
          </Box>
          <List>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.red.light,
                },
              }}
              onClick={navigateToHome}
            >
              <ListItemText
                primary={<Typography variant="button">Home</Typography>}
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.red.light,
                },
              }}
              onClick={navigateToMyApps}
            >
              <ListItemText
                primary={<Typography variant="button">My Apps</Typography>}
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.red.light,
                },
              }}
              onClick={navigateToSupport}
            >
              <ListItemText
                primary={<Typography variant="button">Support</Typography>}
              />
            </ListItemButton>
          </List>
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          anchor="top"
          open={drawerOpen}
          onClose={handleDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: theme.palette.red.main,
            },
          }}
        >
          <List>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.red.light,
                },
              }}
              onClick={navigateToHome}
            >
              <ListItemText
                primary={<Typography variant="button">Home</Typography>}
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.red.light,
                },
              }}
              onClick={navigateToMyApps}
            >
              <ListItemText
                primary={<Typography variant="button">My Apps</Typography>}
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.red.light,
                },
              }}
              onClick={navigateToSupport}
            >
              <ListItemText
                primary={<Typography variant="button">Support</Typography>}
              />
            </ListItemButton>
          </List>
        </Drawer>
      )}
    </Box>
  );
}
