import { useCallback } from "react";

// Filter shop ID with user ID in Airtable
export default function useFilterShopIdWithUserId() {
  const filterShopIdWithUserId = useCallback(
    async (idToken, setState, AppName) => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id_token: idToken,
            app_name: AppName,
          }),
        };
        const response = await fetch(
          process.env.REACT_APP_FILTER_SHOP_ID_WITH_USER_ID,
          requestOptions
        );
        const responseData = await response.json();
        if (responseData.data) {
          setState((prevState) => ({
            ...prevState,
            shopIds: responseData.data,
          }));
        }
      } catch (error) {
        console.log("Error:", error);
      }
    },
    []
  );
  return filterShopIdWithUserId;
}
